const initialData = {
    activeUser: {},
    
};
const userReducer = (state = initialData, action) => {
    switch (action.type) {
        case "GET_ACTIVE_USER":
            return {
                ...state,
                activeUser: action.payload,
            };

        default:
            return state;
    }
};
export default userReducer;