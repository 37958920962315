import firebase from "../../config/firebase";

export const getActiveUser = () => async (dispatch) => {
  firebase
    .firestore()
    .collection("nationaalVakantieonderzoekViaLuxury")
    .onSnapshot(async (data) => {
      let tempData = {};
      if (data.docs) {
        tempData = { ...data.docs[0].data() };
      }
      //   for (let doc of data.docs) {
      //     let id = doc.id;
      //     let data1 = doc.data();
      //     tempData = { id: id, ...data1 };
      //   }
      dispatch({ type: "GET_ACTIVE_USER", payload: tempData });
    });
};

export const setActiveUser =
  (onSuccess = () => {}) =>
  async (dispatch) => {
    // console.log(payload, 'payload');
    try {
      if (localStorage.getItem("uniqueId") == null) {
        let newDocRef = await firebase
          .firestore()
          .collection("nationaalVakantieonderzoekViaLuxuryActiveUsers")
          .doc();
        const uniqueId = newDocRef.id;
        localStorage.setItem("uniqueId", uniqueId);
      }
      let uniqueUser = localStorage.getItem("uniqueId");
      firebase
        .firestore()
        .collection("nationaalVakantieonderzoekViaLuxuryActiveUsers")
        .doc(uniqueUser)
        .set({
          created: firebase.firestore.FieldValue.serverTimestamp(),
        });
    } catch (error) {
      console.log(error);
    }
  };
