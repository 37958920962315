// Root Reducer
import userReducer from "./userReducer";
import { combineReducers } from "redux";
import authUserReducer from "./authUser";
import newLeadReducers from "./leadReducer";

export let rootReducer = combineReducers({
    authUser: authUserReducer,
    lead: newLeadReducers,
    user: userReducer,
});

export default rootReducer;
