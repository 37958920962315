import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import tick from '../assets/img/tick (1).svg'
const Footertop = () => {
    return (
        <Container fluid>
            <Row className='footerTop p-0 mb-0'>
                <Col lg='2' md='4' sm='6'>
                    <div className='d-flex'>
                        <span>
                            <img src={tick} className='tick' alt="" />
                        </span>
                        <p>
                            Gasten beoordelen ons met een 4.5/5 op Trustpilot
                        </p>
                    </div>

                </Col>
                <Col lg='2' md='4' sm='6'>
                    <div className='d-flex'>
                        <span>
                            <img src={tick} className='tick' alt="" />
                        </span>
                        <p>
                            Altijd de beste deal
                        </p>
                    </div>


                </Col>
                <Col lg='2' md='4' sm='6'>
                    <div className='d-flex'>
                        <span>
                            <img src={tick} className='tick' alt="" />
                        </span>
                        <p>
                            Gratis annuleren
                        </p>
                    </div>
                </Col>
                <Col lg='2' md='4' sm='6'>
                    <div className='d-flex'>
                        <span>
                            <img src={tick} className='tick' alt="" />
                        </span>
                        <p>
                            100% geld-terug-garantie
                        </p>
                    </div>

                </Col>
                <Col lg='2' md='4' sm='6'>
                    <div className='d-flex'>
                        <span>
                            <img src={tick} className='tick' alt="" />
                        </span>
                        <p>
                            Ontvang tot wel 70% korting op de mooiste hotels
                        </p>
                    </div>               

                </Col>
                <Col lg='2' md='4' sm='6'>
                    <div className='d-flex'>
                        <span>
                            <img src={tick} className='tick' alt="" />
                        </span>
                        <p>
                            Gasten beoordelen ons met een 4.5/5 op Trustpilot
                        </p>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Footertop
