import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import questionImg from "../assets/img/question-img.png";
import QuestionCard from "./QuestionCard";
import footerimg1 from "../assets/img/footer 1.png";
import footerimg2 from "../assets/img/footer 2.png";
import footerimg3 from "../assets/img/footer 3.png";

const QuestionsSection = ({ handlePageClick }) => {
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [answers, setAnswers] = useState([]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const questions = [
    {
      question: "Wat vind jij een leukere vakantie?",
      answers: [
        {
          name: "Zon vakantie",
          value: "Zon vakantie",
        },
        {
          name: "Wintersport",
          value: "Wintersport",
        },
      ],
    },
    {
      question: "Wat voor soort vakantie heeft jouw voorkeur?",
      answers: [
        {
          name: "City trip",
          value: "City trip",
        },
        {
          name: "Strand vakantie",
          value: "Strand vakantie",
        },
        {
          name: "Actieve vakantie (hiken, wintersport ed)",
          value: "Actieve vakantie (hiken, wintersport ed)",
        },
        {
          name: "Ik vind alles leuk",
          value: "Ik vind alles leuk",
        },
      ],
    },
    {
      question: "Waar overnacht jij het liefst tijdens je vakantie?",
      answers: [
        {
          name: "In een (luxe) hotel",
          value: "City trip",
        },
        {
          name: "Op een camping",
          value: "Op een camping",
        },
        {
          name: "Airbnb/huis huren",
          value: "Airbnb/huis huren",
        },
        {
          name: "Maakt me niet zo uit",
          value: "Maakt me niet zo uit",
        },
      ],
    },
    {
      question: "Met wie ga jij het vaakst  op vakantie?",
      answers: [
        {
          name: "Mijn partner",
          value: "Mijn partner",
        },
        {
          name: "Vrienden",
          value: "Vrienden",
        },
        {
          name: "Mijn gezin/familie",
          value: "Mijn gezin/familie",
        },
        {
          name: "Alleen",
          value: "Alleen",
        },
      ],
    },
  ];

  const handleAnswer = (value) => {
    setAnswers((prev) => [...prev, value]);
    setSelectedAnswer(value);
    setTimeout(() => {
      setSelectedAnswer(null);
      if (currentQuestion === 3) {
        handlePageClick(3);
      } else {
        setCurrentQuestion((prev) => prev + 1);
      }
    }, 1000);
  };

  const removeAnswer = () => {
    setAnswers(answers.slice(0, -1));
  };

  // const handlePreviousQuestion = () => {
  //   setCurrentQuestion((prev) => prev - 1);
  //   removeAnswer();
  // };
  const footer = [
    {
      image: footerimg1,
    },
    {
      image: footerimg2,
    },
    {
      image: footerimg3,
    },
  ];
  const handlePreviousQuestion = () => {
    setTimeout((handlePreviousQuestion) => {
      if (currentQuestion > 0) {
        setCurrentQuestion(currentQuestion - 1);
        removeAnswer();
      }
    }, 1000);
  };

  return (
    <>
      <div className="questions-section">
        <div className="questions-section__top">
          <Container fluid>
            <Row>
              <p className="mb-0">Nationaal Vakantieonderzoek 2023</p>
            </Row>
          </Container>
        </div>

        <Container>
          <Row className="mt-5">
            <Col md={7}>
              <QuestionCard
                details={questions[currentQuestion]}
                currentQuestion={currentQuestion}
                handleAnswer={handleAnswer}
                handlePreviousQuestion={handlePreviousQuestion}
              />
              {/* {questions.map((que) => {
              return (
                <QuestionCard
                  details={que}
                  currentQuestion={currentQuestion}
                  handleAnswer={handleAnswer}
                />
              );
            })} */}
            </Col>
            <Col
              md={5}
              className="d-flex justify-content-center align-items-center mt-md-0 mt-2"
            >
              <img
                src={questionImg}
                alt=""
                className="questions-section__img w-75"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row>
          <span className="Secbottom pt-4 text-center">
            Dit onderzoek wordt mede mogelijk gemaakt door ViaLuxury, de #1 in
            luxe 4- en 5 sterren hotelervaringen.
          </span>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          {footer.map((item) => {
            return (
              <Col
                xl=""
                lg="4"
                md="6"
                sm="8"
                className="d-flex justify-content-around align-items-center py-1"
              >
                <img src={item.image} className="footerimg img-fluid" alt="" />
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default QuestionsSection;
