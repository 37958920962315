import React, { useEffect } from "react";
import { Col, Container, Row } from "reactstrap";
import Arrow from "../assets/img/arrow-right-black.png";
import startimg from "../assets/img/start-img.png";
import footerimg1 from "../assets/img/footer 1.png";
import footerimg2 from "../assets/img/footer 2.png";
import footerimg3 from "../assets/img/footer 3.png";
import { useDispatch, useSelector } from "react-redux";
import { getActiveUser, setActiveUser } from "../store/actions/userAction";

const WelcomeSection = ({ handlePageClick }) => {
  const dispatch = useDispatch();
  const { activeUser } = useSelector((state) => state.user);

  const footer = [
    {
      image: footerimg1,
    },
    {
      image: footerimg2,
    },
    {
      image: footerimg3,
    },
  ];

  useEffect((handlePageClick) => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  useEffect(() => {
    dispatch(getActiveUser());
    dispatch(setActiveUser());
  }, []);

  return (
    <>
      <div className="hero-contents">
       
          <Container fluid>
            <Row className="px-0">
              <Col lg="2"></Col>
              <Col
                md={12}
                lg="6"
                className="py-0 d-flex justify-content-center flex-column h-auto  px-3 hero-contents__text"
              >
                <h1 className="fw-bold hero-contents__text-title">
                  Welkom bij het
                </h1>
                <p className="hero-contents__text-smtitle">
                  Nationaal Vakantieonderzoek 2023
                </p>
              </Col>
              <Col lg="2"></Col>
            </Row>
          </Container>

          <div className="smbg">
            <span className=" hero-contents__text-bgtext">
              Hoe ga jij graag op vakantie?
            </span>
          </div>

          <Container fluid>
            <Row>
              <Col lg="2" md=""></Col>
              <Col lg="6" md="12">
                <div className="d-flex">
                  <small className="ms-3 hero-contents__text-description  mt-3 py-1">
                    <span className="underline">
                      De vakanieperiode komt er weer aan.
                    </span>{" "}
                    Met het Nationaal Vakantieonderzoek willen we meer te weten
                    komen over de vakantiegewoonten van Nederlanders in 2023.{" "}
                  </small>
                </div>

                <div className="py-2">
                  <small className="py-4 hero-contents__text-description ">
                    Voor dit korte onderzoek zoeken wij 1000 deelnemers.
                  </small>
                </div>

                <div>
                  <small className="ms-3 hero-contents__text-description  underline ">
                    Voor elke deelnemer van het Nationaal Vakantieonderzoek 2023
                    ligt een gratis voucher klaar van ViaLuxury t.w.v €20.{" "}
                  </small>
                </div>
                <div className="py-2">
                  <small className="text-start hero-contents__text-description ">
                    ViaLuxury heeft vele luxe hotel aanbiedingen, met op dit
                    moment zelfs een “1+1 gratis” actie. Zo maken zij luxe
                    hotels voor iedereen toegangkelijk.
                  </small>
                </div>

                <button
                  className="hero-contents__text-btn d-flex justify-content-around fw-700 align-items-center pe-2 ps-4 mt-3 mb-1"
                  onClick={() => {
                    handlePageClick(2);
                  }}
                >
                  Start deelname
                  <img src={Arrow} alt="" />
                </button>
                <p className="hero-contents__text-subtext">
                  <span className="hero-contents__text-boldtext">
                    {" "}
                    {activeUser.activeUsers} anderen
                  </span>{" "}
                  nemen op dit moment deel aan het onderzoek.
                </p>
              </Col>
              
              <Col lg="2">
              <img src={startimg} className="startimg" alt="" />
              </Col>
            </Row>
            
          </Container>
          {/* <img src={startimg} className="startimg" alt="" /> */}
        
      </div>

      {/* ///Footer//// */}
      <Container>
        <Row>
          <span className="Secbottom p-4 text-center">
            Dit onderzoek wordt mede mogelijk gemaakt door ViaLuxury, de #1 in
            luxe 4- en 5 sterren hotelervaringen.
          </span>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          {footer.map((item) => {
            return (
              <Col
                xl=""
                lg="4"
                md="6"
                sm="8"
                className="d-flex justify-content-around align-items-center my-3 py-4 "
              >
                <img src={item.image} className="footerimg img-fluid" alt="" />
              </Col>
            );
          })}
        </Row>
      </Container>
    </>
  );
};

export default WelcomeSection;
