import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const Footer = () => {
  
  const privacyUrl = "https://vialuxury.com/nl-nl/privacy-cookies";
  return (
    <>
      
      <Container fluid>
        <Row >
          <Col lg='12' className='footer'>
            <span className='d-flex justify-content-center align-item-center privacytext'>
              <a href={privacyUrl} target='_blank' rel="noopener noreferrer">
              Privacy statement{""}
              </a>
            </span>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Footer