import firebase from "firebase";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBwiMA4HScwhYjDRMlcoGzmjKofnuWlXiY",
  authDomain: "republish-react.firebaseapp.com",
  projectId: "republish-react",
  storageBucket: "republish-react.appspot.com",
  messagingSenderId: "249306848505",
  appId: "1:249306848505:web:f1fc4666522206c9de5628",
  measurementId: "G-H87KB5VR5X",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;
