import React from "react";
import { Container } from "reactstrap";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";


const Header = () => {
  return (
    <div className="header">
      <Container className="d-flex justify-content-between align-items-center ">
        <Link to="/Home">
          <img src={logo} alt="Logo" />
        </Link>

        <p className="mb-0">1000 deelnemers gezocht!</p>
      </Container>
    </div>
  );
};

export default Header;
