import React from "react";
import { useSelector } from "react-redux";
import { Container } from "reactstrap";

const Bedankt = () => {
  const { create_response } = useSelector((state) => state.lead);
  return (
    <div className="thankyou">
      <Container>
        <div className="thankyou__card">
          <h3 className="text-center">Bedankt voor uw aanvraag!</h3>
        </div>
        {create_response?.profile && (
          <img
            referrerpolicy="no-referrer-when-downgrade"
            src={`https://republish.startdeelname.nl/m/5750/07741f8ac75a/?event=6713&unique_conversion_id=${create_response?.profile.id}`}
            style={{
              width: "1px",
              height: "1px",
              border: "0px",
            }}
          ></img>
        )}
      </Container>
    </div>
  );
};

export default Bedankt;
