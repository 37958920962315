import { Link } from "react-router-dom";
import HeroSection from "../components/HeroSection";
import QuestionsSection from "../components/QuestionsSection";
import WelcomeSection from "../components/WelcomeSection";
import { useState } from "react";

const Home = (props) => {
  const [page, setPage] = useState(1);

  const handlePageClick = (val) => {
    setPage(val);
  };
  return (
    <>
      {page == 1 && <WelcomeSection handlePageClick={handlePageClick} />}
      {page == 2 && <QuestionsSection handlePageClick={handlePageClick} />}
      {page == 3 && <HeroSection handlePageClick={handlePageClick} />}
    </>
  );
};

export default Home;
