import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import main from "../assets/img/main.png";
import rating from "../assets/img/rating-logo.png";
import trust from "../assets/img/trust.png";
import arrowDown from "../assets/img/arrow-down.png";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { createNewLead } from "../store/actions/leadAction";
import arrow from "../assets/img/arrow-right-orange.png";
import logo from "../assets/img/logo.png";
import Footertop from "./Footertop";
const HeroSection = ({ handlePageClick }) => {
  const [answers, setAnswers] = useState([3833]);
  const history = useHistory();
  const [currDay, setCurrDay] = useState("");
  const [currMonth, setCurrMonth] = useState("");
  const [currYear, setCurrYear] = useState("");
  const [details, setDetails] = useState({
    gender: "",
    firstname: "",
    lastname: "",
    email: "",
    site_subid: "",
    publisher_id: "",
    date_of_birth: "",
    language: "nl_NL",
    site_custom_url: "http://vakantieonderzoek.startdeelname.nl/",
    site_custom_name: "vakantieonderzoek_vialuxury",
  });
  const { loading } = useSelector((state) => state.lead);

  const dispatch = useDispatch();

  const handleInputChange = (e) => {
    let { name, value } = e.target;

    setDetails((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };
  const days = [];
  for (let i = 1; i <= 31; i++) {
    days.push(i.toString().padStart(2, "0"));
  }

  const months = [
    { label: "Jan", value: "01" },
    { label: "Feb", value: "02" },
    { label: "Mar", value: "03" },
    { label: "Apr", value: "04" },
    { label: "May", value: "05" },
    { label: "Jun", value: "06" },
    { label: "Jul", value: "07" },
    { label: "Aug", value: "08" },
    { label: "Sep", value: "09" },
    { label: "Oct", value: "10" },
    { label: "Nov", value: "11" },
    { label: "Dec", value: "12" },
  ];

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= currentYear - 100; i--) {
    years.push(i);
  }
  useEffect(() => {
    const query = history.location.search;
    if (query) {
      const site_subid = new URLSearchParams(query).get("oa_id");
      const publisher_id = new URLSearchParams(query).get("site");
      setDetails((prev) => ({ ...prev, site_subid, publisher_id }));
    }
  }, []);

  const firstNameCheck = () => {
    let firstNameRegex = /^([a-zA-Z ]){1,30}$/;
    if (details.firstname != "") {
      if (firstNameRegex.test(details.firstname)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const lastNameCheck = () => {
    let lastNameRegex = /^([a-zA-Z ]){1,30}$/;
    if (details.lastname != "") {
      if (lastNameRegex.test(details.lastname)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };
  const emailCheck = () => {
    let emailRejex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (details.email != "") {
      if (emailRejex.test(details.email)) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const ageCalculator = (dob) => {
    var dob = new Date(dob);

    var dobYear = dob.getYear();
    var dobMonth = dob.getMonth();
    var dobDate = dob.getDate();

    var now = new Date();
    var currentYear = now.getYear();
    var currentMonth = now.getMonth();
    var currentDate = now.getDate();

    var age = {};
    var ageString = "";

    var yearAge = currentYear - dobYear;
    if (currentMonth >= dobMonth) var monthAge = currentMonth - dobMonth;
    else {
      yearAge--;
      var monthAge = 12 + currentMonth - dobMonth;
    }

    if (currentDate >= dobDate) var dateAge = currentDate - dobDate;
    else {
      monthAge--;
      var dateAge = 31 + currentDate - dobDate;

      if (monthAge < 0) {
        monthAge = 11;
        yearAge--;
      }
    }
    age = {
      years: yearAge,
      months: monthAge,
      days: dateAge,
    };
    return age;
  };
  const [errorDetails, setErrorDetails] = useState({
    genderError: false,
    ageError: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!firstNameCheck() && !lastNameCheck() && !emailCheck()) {
      if (details.gender != "") {
        let datOfBirth = currYear + "-" + currMonth + "-" + currDay;
        // console.log("Date of birth", datOfBirth, ageCalculator(datOfBirth));
        let age_calculated = ageCalculator(datOfBirth);
        if (age_calculated.years < 23) {
          history.push("/bedankt");
        } else {
          dispatch(
            createNewLead(
              { ...details, date_of_birth: datOfBirth, answers },
              () => {
                history.push("/bedankt");
              }
            )
          );
        }
      } else {
        setErrorDetails((prev) => ({ ...prev, genderError: true }));
        setTimeout(() => {
          setErrorDetails((prev) => ({ ...prev, genderError: false }));
        }, 3000);
      }
    }
  };

  return (
    <>
      <div className="hero-section">
        <Container>
          <Row className="">
            <Col lg="7" className="d-flex flex-column hero-section__left h-100">
              <p className="mb-0 hero-section__left-title">
                Bedankt voor uw deelname aan het{" "}
              </p>
              <p className="mb-0 hero-section__left-sub-title">
                Nationaal Vakantieonderzoek 2023
              </p>
              <p className="mb-0 hero-section__left-description">
                mede mogelijk gemaakt door ViaLuxury
              </p>
              <p className="mb-0 hero-section__left-text mt-4">
                We hebben voor elke deelnemer
              </p>
              <p className="mb-0 hero-section__left-text">
                het{" "}
                <span className="hero-section__left-text-yellow">
                  Nationaal Vakantieonderzoek 2023
                </span>{" "}
              </p>{" "}
              <p className="mb-0 hero-section__left-text">
                van een gratis cadeaukaart klaarliggen van
              </p>
              <p className="mb-0 hero-section__left-text">
                ViaLuxury{" "}
                <span className="hero-section__left-text-yellow">
                  t.w.v. €20,-{" "}
                </span>{" "}
              </p>
              <p className="mb-0 hero-section__left-text mt-3">
                ViaLuxury maakt luxe
              </p>
              <p className="mb-0 hero-section__left-text ">
                hotelovernachtingen beschikbaar voor
              </p>
              <p className="mb-0 hero-section__left-text ">
                iedereen: ontvang hoge kortingen op
              </p>
              <p className="mb-0 hero-section__left-text ">vele luxe hotels.</p>
              <img src={main} alt="" className="w-50" />
              <img
                src={arrowDown}
                alt=""
                className="hero-section__left-arrow"
              />
            </Col>

            <Col lg="5" className="h-100 hero-section__right">
              <Form onSubmit={handleSubmit}>
                <div className="hero-section__card">
                  <p className="mb-0 hero-section__card-title">
                    Bedankt voor je deelname.
                  </p>
                  <p className="mb-0 hero-section__card-sub-title">
                    Naar wie mogen we de{" "}
                  </p>
                  <p className="mb-0 hero-section__card-sub-title">
                    cadeaubon sturen?
                  </p>
                  <div className=" d-flex mt-2">
                    <FormGroup check className="hero-section__card-smradio">
                      <Input
                        name="gender"
                        type="radio"
                        value="male"
                        onChange={handleInputChange}
                      />{" "}
                      <Label check>Mevrouw</Label>
                    </FormGroup>
                    <FormGroup check className="ml-5">
                      <Input
                        name="gender"
                        type="radio"
                        value="female"
                        onChange={handleInputChange}
                      />{" "}
                      <Label check>Meneer</Label>
                    </FormGroup>
                  </div>
                  {errorDetails?.genderError && (
                    <small className="text-danger"> Selecteer een optie</small>
                  )}
                  <FormGroup className="mt-2">
                    <Input
                      type="text"
                      placeholder="Voornaam"
                      name="firstname"
                      invalid={firstNameCheck()}
                      value={details.firstname}
                      onChange={handleInputChange}
                      required
                    />
                    {firstNameCheck() && (
                      <small className="text-danger">
                        {" "}
                        Voer een geldige voornaam in
                      </small>
                    )}
                  </FormGroup>
                  <FormGroup className="mt-1">
                    <Input
                      type="text"
                      name="lastname"
                      placeholder="Achternaam"
                      value={details.lastname}
                      invalid={lastNameCheck()}
                      onChange={handleInputChange}
                      required
                    />
                    {lastNameCheck() && (
                      <small className="text-danger">
                        Voer een geldige achternaam in
                      </small>
                    )}
                  </FormGroup>
                  <FormGroup className="mt-1">
                    <Input
                      type="text"
                      placeholder="E-mail adres"
                      name="email"
                      invalid={emailCheck()}
                      value={details.email}
                      onChange={handleInputChange}
                      required
                    />
                    {emailCheck() && (
                      <small className="text-danger">
                        Voer een geldige e-mail adres in
                      </small>
                    )}
                  </FormGroup>{" "}
                  <Row noGutters className="justify-content-between">
                    <Col xs="12" className="">
                      <Label>Geboortedatum</Label>
                    </Col>

                    <Col xs="3">
                      <FormGroup>
                        <Input
                          type="select"
                          placeholder="Dag"
                          value={currDay}
                          onChange={(e) => setCurrDay(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            Dag
                          </option>
                          {days?.map((day, index) => {
                            return (
                              <option value={day} key={index}>
                                {day}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        {/* <Input type="text" placeholder="Maand" /> */}
                        <Input
                          type="select"
                          placeholder="Maand"
                          value={currMonth}
                          onChange={(e) => setCurrMonth(e.target.value)}
                          required
                          
                        >
                          <option  value="" default disabled>
                            Maand
                          </option>
                          {months?.map((mon, index) => {
                            return (
                              <option value={mon.value} key={index}>
                                {mon.label}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col xs="4">
                      <FormGroup>
                        {/* <Input type="text" placeholder="Jar" /> */}
                        <Input
                          type="select"
                          placeholder="Dag"
                          value={currYear}
                          onChange={(e) => setCurrYear(e.target.value)}
                          required
                        >
                          <option value="" disabled>
                            Jaar
                          </option>
                          {years?.map((year, index) => {
                            return (
                              <option value={year} key={index}>
                                {year}
                              </option>
                            );
                          })}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Button
                    className="mt-4 d-flex justify-content-center align-items-center w-100"
                    // onClick={() => handlePageClick(2)}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <Spinner size="sm" />
                    ) : (
                      <>
                        Bevestig deelname{" "}
                        <i className="fa fa-arrow-right ml-3"></i>
                      </>
                    )}
                  </Button>
                  <p className="mt-3 mb-0 hero-section__card-bottom-text">
                    Door op bovenstaande knop te klikken geef je ViaLuxury
                    toestemming jou via e-mail te informeren en op de hoogte te
                    houden over de beste acties.
                  </p>
                  <div className="mt-3 d-flex  flex-column justify-content-center align-items-center w-40">
                    <img src={trust} alt="" className="w-50" />
                    <img src={rating} alt="" className="w-50" />
                  </div>
                </div>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>

      <Container>
        <Row>
          <span className="Secbottom p-4 text-center">
            Dit onderzoek wordt mede mogelijk gemaakt door ViaLuxury, de #1 in
            luxe 4- en 5 sterren hotelervaringen.
          </span>
        </Row>
      </Container>
      <div>
        <Footertop></Footertop>
      </div>
      <div className="footer">
        <Container>
          <Row>
            <Col lg="8" className="d-flex flex-column footer__main h-100">
              <p className="mb-0 p-0  firsthead">
                ViaLuxury is de#1 in luxe 4- en 5 sterren hotelervaringen{" "}
              </p>

              <div className="arrow">
                <span>
                  <img src={arrow} className="setArrow" alt="" />
                </span>
                <p className="mb-0 sectext">
                  Profiteer het hele jaar door van exclusieve kortingen op luxe
                  hotel deals;{" "}
                </p>
              </div>

              <div className="arrow">
                <span>
                  <img src={arrow} className="setArrow" alt="" />
                </span>
                <p className="mb-0 sectext">
                  Voor ieder wat wils: van welness arrangement tot sportieve
                  getaway;{" "}
                </p>
              </div>

              <div className="arrow">
                <span>
                  <img src={arrow} className="setArrow" alt="" />
                </span>
                <p className="mb-0 sectext">
                  Ontdek de meest prachtige locaties;{" "}
                </p>
              </div>

              <div className="arrow">
                <span>
                  <img src={arrow} className="setArrow" alt="" />
                </span>
                <p className="mb-0 sectext">Gemakkelijk te annuleren; </p>
              </div>
            </Col>
            <Col lg="4" className="d-flex footer__main ">
              <img src={logo} className="logo" alt="" />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HeroSection;
