import React, { useState } from "react";
import { FormGroup, Input, Label } from "reactstrap";
import Arrow from "../assets/img/arrow-right-orange.png";

const QuestionCard = ({
  details,
  currentQuestion,
  handleAnswer,
  handlePreviousQuestion,
}) => {
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  const [isAnswering, setIsAnswering] = useState(false);

  const handleOptionClick = (idx) => {
    setIsAnswering(true);
    setSelectedAnswer(idx);
    handleAnswer(details.answers[idx].value);
    setTimeout(() => {
      setSelectedAnswer("");
      setIsAnswering(false);
    }, 1000);
  };

  const showArrow = currentQuestion !== 0;

  return (
    <div className="question-card">
      <p className="mb-0 question-card__text">
        Vraag {currentQuestion + 1} van 4
      </p>
      <p className="mb-0 question-card__question">{details?.question}</p>

      {details?.answers.map((ans, idx) => {
        const isAnswerSelected = idx === selectedAnswer;

        return (
          <div
            className={`question-card__check ${
              isAnswerSelected ? "selected" : ""
            }`}
            key={idx}
            onClick={() => {
              if (isAnswering == false) {
                handleOptionClick(idx);
              }
              // handleOptionClick(idx);
            }}
          >
            <FormGroup check>
              <Input
                name={ans.name}
                type="radio"
                value={ans.value}
                checked={isAnswerSelected}
                onChange={() => {}}
              />{" "}
              <Label check className="question-card__label">
                {ans?.name}
              </Label>
            </FormGroup>
          </div>
        );
      })}

      {showArrow && (
        <img
          src={Arrow}
          alt=""
          onClick={handlePreviousQuestion}
          className="question-card__arrow"
        />
      )}
    </div>
  );
};

export default QuestionCard;
